"use client";
import React from "react";
import { cn, genaratePrdLink } from "@/lib/utils";
import { ProductTypes, StockDataTypes } from "@/app/admin/store/admin-products";
import Image from "next/image";
import { ProductImageTypes } from "@/types/products-types";
import { Separator } from "../ui/separator";
import {
  Carousel,
  CarouselMainContainer,
  SliderMainItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel-embla";
import { DiscountTypes } from "@/types/discount-types";
import Link from "next/link";

const OfferCarousel = ({
  d,
  products,
}: {
  d: DiscountTypes;
  products: ProductTypes[] | null;
}) => {
  return (
    <Carousel
      carouselOptions={{
        loop: true,
        align: "center",
      }}
      className="relative pt-4"
    >
      {" "}
      <CarouselNext
        className="pointer-events-auto absolute z-40 size-9"
        disabled={false}
      />
      <CarouselPrevious
        className="pointer-events-auto absolute z-40 size-9"
        disabled={false}
      />
      <CarouselMainContainer className="h-full w-full">
        {d &&
          d?.category_data?.map((c, index) => {
            const product = products?.find((p) => p.id === c.product_id);
            const stockData = product?.stock_data as StockDataTypes;
            const productImages = product?.product_images as ProductImageTypes;
            if (Array.isArray(stockData))
              return stockData.map((s) => {
                return (
                  <SliderMainItem
                    key={index}
                    className={cn(
                      "mx-3 w-auto basis-auto cursor-pointer select-none bg-transparent",
                      "min-w-60 basis-1/2 max-sm:flex max-sm:basis-full max-sm:flex-col max-sm:items-center md:basis-1/3",
                    )}
                  >
                    <span className="flex flex-row gap-1 whitespace-normal text-wrap text-lg font-semibold tracking-wide max-sm:text-sm">
                      {product?.product_name}
                    </span>
                    <span className="whitespace-normal pb-2 text-base opacity-80 max-sm:text-xs">
                      Special Offer Of {c.discount_percentage}% Discount.
                    </span>
                    <div className="grid w-fit gap-4 max-sm:p-3">
                      <div className=" flex h-full w-fit flex-col items-start gap-4 rounded-md p-4 pt-4">
                        <Link
                          href={genaratePrdLink(
                            product?.product_name,
                            c?.product_id!,
                          )}
                          className=" relative aspect-square max-h-48 w-full self-center sm:max-h-56"
                        >
                          <Image
                            src={productImages[0].img}
                            alt={productImages[0].alt}
                            width={800}
                            priority
                            height={800}
                            draggable={false}
                            className="pointer-events-none object-cover object-center"
                          />
                        </Link>
                        <Separator />
                        <div className="flex flex-col items-center justify-start gap-2">
                          <span
                            key={index}
                            className="flex flex-row items-center gap-2"
                          >
                            <span className="text-xs line-through">
                              {" "}
                              {s.price}LKR
                            </span>
                            <span className="text-lg font-semibold">
                              {" "}
                              {s.price *
                                ((100 - c?.discount_percentage!) / 100)}
                              LKR
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </SliderMainItem>
                );
              });
          })}
      </CarouselMainContainer>
    </Carousel>
  );
};

export default OfferCarousel;
