"use client";
import React from "react";
import { cn } from "@/lib/utils";
import Image from "next/image";
import { Separator } from "../ui/separator";
import {
  Carousel,
  CarouselMainContainer,
  CarouselNext,
  CarouselPrevious,
  SliderMainItem,
} from "@/components/ui/carousel-embla";
import { PrebuiltImageTypes, PrebuiltsTypes } from "@/types/prebuilt-types";
import Link from "next/link";

const PrebuiltCarousel = ({ d }: { d: PrebuiltsTypes[] }) => {
  return (
    <Carousel
      carouselOptions={{
        loop: true,
        align: "center",
      }}
      className="pt-4"
    >
      {" "}
      <CarouselNext
        className="pointer-events-auto absolute z-40 size-9"
        disabled={false}
      />
      <CarouselPrevious
        className="pointer-events-auto absolute z-40 size-9"
        disabled={false}
      />
      <CarouselMainContainer className="h-auto">
        {d &&
          d.map((p) => {
            const prebuiltImages =
              p?.built_imgs as unknown as PrebuiltImageTypes | null;
            return (
              <SliderMainItem
                key={p.built_id}
                className={cn(
                  "mx-3 w-auto basis-auto cursor-pointer select-none bg-transparent",
                  "min-w-60 basis-1/2 max-sm:flex max-sm:basis-full max-sm:flex-col max-sm:items-center md:basis-1/3",
                )}
              >
                <span className="flex flex-row gap-1 whitespace-normal text-wrap text-lg font-semibold tracking-wide max-sm:text-sm">
                  {p.built_name}
                </span>

                <div className="grid w-fit gap-4 max-sm:p-3">
                  <div className=" flex h-full w-fit flex-col items-start gap-4 rounded-md p-4 pt-4">
                    <Link
                      href={`/build/prebuilts/${p.category}/${p.built_id}`}
                      className=" relative aspect-square max-h-48 w-full self-center sm:max-h-56"
                    >
                      {prebuiltImages && prebuiltImages?.length !== 0 && (
                        <Image
                          src={prebuiltImages[0].img}
                          alt={prebuiltImages[0].alt}
                          width={800}
                          priority
                          height={800}
                          draggable={false}
                          className="pointer-events-none object-cover object-center"
                        />
                      )}
                    </Link>
                    <Separator />
                    <div className="flex flex-col items-center justify-start gap-2">
                      <span className="text-lg font-semibold">
                        {" "}
                        {p.built_price}
                        LKR
                      </span>
                    </div>
                  </div>
                </div>
              </SliderMainItem>
            );
          })}
      </CarouselMainContainer>
    </Carousel>
  );
};

export default PrebuiltCarousel;
