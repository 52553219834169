import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function genaratePrdLink(
  productName: string | undefined,
  id: string | undefined,
) {
  const link =
    "/products/product/" +
    productName?.replace(/\s+/g, "-").replace(/\W+/g, "_").toLowerCase() +
    "/" +
    id;

  return link;
}

export function formatProductStatus(status: string) {
  let updatedStatus = status.replace("product", "");

  updatedStatus = updatedStatus.replace(/-/g, " ");

  const words = updatedStatus.trim().split(" ");

  const formattedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return formattedWords.join(" ");
}

export const getQueryState = (query: string | null, fallback: string) => {
  return query ? decodeURIComponent(query) : fallback;
};