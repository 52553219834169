import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/playtech-store/htdocs/playtechstore.lk/components/home/home-image-slideshow.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/playtech-store/htdocs/playtechstore.lk/components/home/offer-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/playtech-store/htdocs/playtechstore.lk/components/home/prebuilt-carousel.tsx");
;
import(/* webpackMode: "eager" */ "/home/playtech-store/htdocs/playtechstore.lk/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/playtech-store/htdocs/playtechstore.lk/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/playtech-store/htdocs/playtechstore.lk/public/background.jpg");
