"use client";

import React, { useMemo } from "react";
import Image from "next/image";
import Autoplay from "embla-carousel-autoplay";
import { Carousel, CarouselContent, CarouselItem } from "../ui/carousel";

const HomeImageSlideshow = React.memo(
  ({
    slideShowData,
  }: {
    slideShowData: {
      created_at: string;
      setting_id: string;
      setting_name: string | null;
      settings_json: {
        images: string[];
      };
    } | null;
  }) => {
    const autoPlayPlugin = Autoplay({
      delay: 1600,
      stopOnInteraction: false,
    });

    return (
      <div className="relative w-full max-sm:pt-14">
        <Carousel
          opts={{
            watchDrag: false,
            align: "start",
            duration: 38,
            loop: true,
          }}
          className="mt-0 h-full w-full"
          plugins={[autoPlayPlugin]}
        >
          <CarouselContent className="ml-0">
            {slideShowData?.settings_json ? (
              slideShowData.settings_json.images.map((imageUrl, index) => (
                <CarouselItem key={index} className="relative pl-0 sm:pl-14">
                  <div className="relative">
                    <Image
                      draggable={false}
                      loading={index === 0 ? "eager" : "lazy"}
                      priority={index === 0}
                      width={2088}
                      height={1044}
                      src={imageUrl}
                      alt={`Slide ${index + 1}`}
                      className="aspect-[16/6] h-auto w-full bg-secondary object-cover object-center"
                    />
                  </div>
                </CarouselItem>
              ))
            ) : (
              <CarouselItem className="relative w-full">
                <div className="relative"></div>
              </CarouselItem>
            )}
          </CarouselContent>
        </Carousel>
      </div>
    );
  },
);

HomeImageSlideshow.displayName = "HomeImageSlideshow";

export default HomeImageSlideshow;
